<template>
  <div class="fbfContent">
    <div class="site__wrapper">
      <div class="site__container">
        <div class="_fbform">
          <form @submit.prevent="onSubmit" class="form" action>
            <div class="n-row">
              <div class="ccol-lg-6">
                <div class="fbfgroup _mb30">
                  <label class="lbl-input"
                    >{{ $t("form.name") }}
                    <span class="fbfReq" v-show="formValidations.name.required"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="fbffield"
                    placeholder="Juan"
                    required
                    v-model="form.name"
                    @blur="updateErrors('name')"
                    @change="updateErrors('name')"
                  />
                  <span class="error required" v-if="formError.name">{{
                    $t("form.error")
                  }}</span>
                </div>
              </div>
              <div class="ccol-lg-6">
                <div class="fbfgroup">
                  <label class="lbl-input"
                    >{{ $t("form.last_name") }}
                    <span
                      class="fbfReq"
                      v-show="formValidations.last_name.required"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="fbffield"
                    placeholder="Pérez"
                    required
                    v-model="form.last_name"
                    @blur="updateErrors('last_name')"
                    @change="updateErrors('last_name')"
                  />
                  <span class="error required" v-if="formError.last_name">{{
                    $t("form.error")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="n-row">
              <div class="ccol-lg-6">
                <div class="fbfgroup">
                  <label class="lbl-input"
                    >{{ $t("form.email") }}
                    <span class="fbfReq" v-show="formValidations.email.required"
                      >*</span
                    ></label
                  >
                  <input
                    type="email"
                    class="fbffield"
                    :placeholder="$t('form.email_holder')"
                    v-model="form.email"
                    @blur="updateErrors('email')"
                    @change="updateErrors('email')"
                  />
                  <span class="error required" v-if="formError.email">{{
                    $t("form.error")
                  }}</span>
                  <span
                    class="error email required"
                    v-if="formFormatError.email"
                  >
                    {{ $t("form.email_error") }}
                  </span>
                </div>
              </div>
              <div class="ccol-lg-6">
                <div class="fbfgroup">
                  <label class="lbl-input"
                    >{{ $t("form.phone") }}
                    <span class="fbfReq" v-show="formValidations.phone.required"
                      >*</span
                    ></label
                  >
                  <input
                    type="tel"
                    class="fbffield"
                    placeholder="(787) 333-3333"
                    required
                    v-model="form.phone"
                    v-mask="'(###) ###-####'"
                    @blur="updateErrors('phone')"
                    @change="updateErrors('phone')"
                  />
                  <span class="error required" v-if="formError.phone">{{
                    $t("form.error")
                  }}</span>
                  <span
                    class="error email required"
                    v-if="formFormatError.phone"
                  >
                    {{ $t("form.phone_error") }}
                  </span>
                </div>
              </div>
            </div>
            <!--            <div class="n-row">-->
            <!--              <div class="ccol-lg-6">-->
            <!--                <div class="fbfgroup">-->
            <!--                  <label-->
            <!--                          class="lbl-input"-->
            <!--                  >{{ $t("form.company") }}-->
            <!--                    <span class="fbfReq" v-show="formValidations.company.required">*</span></label-->
            <!--                  >-->
            <!--                  <input-->
            <!--                          type="text"-->
            <!--                          class="fbffield"-->
            <!--                          v-model="form.company"-->
            <!--                          @blur="updateErrors('company')"-->
            <!--                          @change="updateErrors('company')"-->
            <!--                  />-->
            <!--                  <span class="error required" v-if="formError.company">{{-->
            <!--                    $t("form.error")-->
            <!--                  }}</span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="ccol-lg-6">-->
            <!--                <div class="fbfgroup is_client-field">-->
            <!--                  <div class="fbfst">{{ $t("form.is_client.title") }}</div>-->
            <!--                  <div class="bgfst-group">-->
            <!--                    <div class="fbfr fbfr-0" :class="[-->
            <!--                            form.is_client === '1' ? 'active' : ''-->
            <!--                    ]">-->
            <!--                      <input-->
            <!--                              type="radio"-->
            <!--                              id="is_client_yes"-->
            <!--                              name="is-client"-->
            <!--                              value="1"-->
            <!--                              v-model="form.is_client"-->
            <!--                      />-->
            <!--                      <label for="is_client_yes">{{-->
            <!--                        $t("form.is_client.yes")-->
            <!--                        }}</label>-->
            <!--                    </div>-->
            <!--                    <div class="fbfr fbfr-1" :class="[-->
            <!--                            form.is_client === '0' ? 'active' : ''-->
            <!--                    ]">-->
            <!--                      <input-->
            <!--                              type="radio"-->
            <!--                              id="is_client_no"-->
            <!--                              name="is-client"-->
            <!--                              value="0"-->
            <!--                              v-model="form.is_client"-->
            <!--                      />-->
            <!--                      <label for="is_client_no">{{ $t("form.is_client.no") }}</label>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="n-row">-->
            <!--              <div class="ccol-lg-6">-->
            <!--                <div class="fbfgroup">-->
            <!--                  <label for="branch-office" class="lbl-input">{{-->
            <!--                    $t("form.branch_office.lbl")-->
            <!--                    }}</label>-->
            <!--                  <select-->
            <!--                          class="fbfsel"-->
            <!--                          id="branch-office"-->
            <!--                          name="branch-office"-->
            <!--                          v-model="form.branch_office"-->
            <!--                  >-->
            <!--                    <option value="" selected>{{-->
            <!--                      $t("form.branch_office.select")-->
            <!--                      }}</option>-->
            <!--                    <option v-for="(branch, index) in helpers.getBranches()" :key="index">{{-->
            <!--                      branch-->
            <!--                      }}</option>-->
            <!--                  </select>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="ccol-lg-6">-->
            <!--                <div class="fbfgroup">-->
            <!--                  <label for="information" class="lbl-input">{{-->
            <!--                    $t("form.information.lbl")-->
            <!--                    }}</label>-->
            <!--                  <select-->
            <!--                          class="fbfsel"-->
            <!--                          id="information"-->
            <!--                          name="information"-->
            <!--                          v-model="form.information_about"-->
            <!--                  >-->
            <!--                    <option value="" selected>{{-->
            <!--                      $t("form.information.select")-->
            <!--                      }}</option>-->
            <!--                    <option v-for="(information, index) in helpers.getServices(languageChange)" :key="index">{{-->
            <!--                      information-->
            <!--                      }}</option>-->
            <!--                  </select>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="n-row">
              <div class="ccol-lg-12">
                <div class="fbfgroup">
                  <label class="lbl-input"
                    >{{ $t("form.message") }}
                    <span
                      class="fbfReq"
                      v-show="formValidations.message.required"
                      >*</span
                    >
                  </label>
                  <textarea
                    type="text"
                    class="fbffield"
                    v-model="form.message"
                    @blur="updateErrors('message')"
                    @change="updateErrors('message')"
                  />
                  <span class="error required" v-if="formError.message">{{
                    $t("form.error")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="n-row n-row-at">
              <div class="ccol-lg-12">
                <div class="fbfc accept-terms">
                  <input
                    type="checkbox"
                    class="fbfcheck"
                    id="accept-terms"
                    v-model="form.terms"
                    @change="updateErrors('terms')"
                  />
                  <label for="accept-terms"
                    ><span class="terms-text">{{
                      $t("form.terms")
                    }}</span></label
                  >
                  <span class="error required" v-if="formError.terms">{{
                    $t("form.error")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="n-row n-row-submit">
              <div class="ccol-lg-8">
                <div class="recaptcha-box">
                  <vue-recaptcha
                    :sitekey="captchaKey"
                    :loadRecaptchaScript="true"
                    @verify="onVerify"
                  ></vue-recaptcha>
                </div>
              </div>
              <div class="ccol-lg-4">
                <button
                  type="submit"
                  class="fbfbtn"
                  :class="{ submit: submitting }"
                  :disabled="!formIsReady || submitting === true"
                >
                  {{ $t("form.btn") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer
></script>
<script>
import { postWebForm } from "@/api/form";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Home",
  data() {
    return {
      sendingCode: false,
      captchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
      formIsReady: false,
      form: {
        webform_id: "florida_deposit_form",
        name: "",
        last_name: "",
        email: "",
        phone: "",
        // company: '',
        // is_client: '',
        // branch_office: '',
        // information_about: '',
        message: "",
        terms: ""
        // lang: this.$i18n.locale,
      },
      formError: {
        name: "",
        last_name: "",
        email: "",
        phone: "",
        // company: '',
        // is_client: '',
        // branch_office: '',
        // information_about: '',
        message: "",
        terms: ""
      },
      formFormatError: {
        email: false,
        phone: false
      },
      formValidations: {
        name: {
          required: true
        },
        last_name: {
          required: true
        },
        email: {
          required: true
        },
        phone: {
          required: true
        },
        // company: {
        //   required: false
        // },
        // is_client: {
        //   required: false
        // },
        // branch_office: {
        //   required: false
        // },
        // information_about: {
        //   required: false
        // },
        message: {
          required: true
        },
        terms: {
          required: true
        }
      },
      show: true,
      submitting: false,
      submissionId: null,
      robot: false,
      helpers: this.$helperList
    };
  },
  components: {
    VueRecaptcha
  },
  created() {
    this.addLangAttribute();
  },
  computed: {
    languageChange() {
      return this.$i18n.locale;
    }
  },
  methods: {
    onVerify(response) {
      if (response) this.robot = true;
      this.checkFormIsReady();
    },
    updateErrors(e) {
      const value = this.form[e];
      const phones = ["phone"];
      if (this.formValidations[e].required) {
        if (!value) {
          this.formError[e] = true;
          this.formFormatError[e] = false;
        } else {
          this.formError[e] = false;
          this.formFormatError[e] = false;
        }
      }
      // console.log(value);
      if (e === "email" && value) {
        this.formFormatError[e] = !this.validEmail(value);
      }
      if (phones.includes(e) && value) {
        this.formFormatError[e] = !this.validPhone(value);
      }

      this.checkFormIsReady();
    },
    checkFormIsReady() {
      let allRequiredSet = true;
      let allFormatErrorsClean = true;
      let allFormErrorClean = true;
      Object.keys(this.formValidations).forEach(key => {
        if (this.formValidations[key].required) {
          if (!this.form[key]) {
            allRequiredSet = false;
          }
        }
      });
      Object.keys(this.formError).forEach(key => {
        if (this.formError[key]) {
          allFormErrorClean = false;
        }
      });
      Object.keys(this.formFormatError).forEach(key => {
        if (this.formFormatError[key]) {
          allFormatErrorsClean = false;
        }
      });
      this.formIsReady =
        allRequiredSet &&
        allFormatErrorsClean &&
        allFormErrorClean &&
        this.robot;
    },
    validPhone(phone) {
      // console.log("Validate phone", phone);
      const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
      return re.test(phone);
    },
    validEmail(email) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    addLangAttribute() {
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
    },
    removeSpecialChars(str) {
      if (str) {
        return str
          .replace(/(?!\w|\s)./g, "")
          .replace(/\s+/g, " ")
          .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2");
      }

      return "";
    },
    onSubmit(event) {
      if (this.robot) {
        this.submitting = true;
        this.sendData();
      }
      event.preventDefault();
    },
    async sendData() {
      const submitValues = this.form;
      const lang = this.$i18n.locale;
      this.submitting = true;
      const postResponse = await postWebForm(submitValues, lang)
        .then(({ data }) => {
          if (data.sid) {
            this.submissionId = data.sid;
            this.$router.push({ name: "thanksPage" });
            return true;
          }
          this.submissionId = null;
          return false;
        })
        .catch(() => {
          this.submissionId = null;
          return false;
        })
        .finally(() => {
          // console.log("FINISH");
          this.submitting = false;
        });
      if (postResponse) {
        // console.log("Send TRUE");
      } else {
        // console.log("Send FALSE");
      }
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.fbfContent {
  @media (max-width: 650px) {
    padding-top: 0;
    padding-bottom: 0;
    #rc-anchor-container {
      width: 80% !important;
    }
  }
}
._fbform {
  max-width: 650px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 20px;
  @media (max-width: 650px) {
    margin-top: 0;
    padding-top: 45px;
  }
  label {
    font-size: 13px;
    color: #303030;
    margin-bottom: 7px;
    .fbfReq {
      color: #f00;
      font-weight: 300;
    }
  }
  .terms-text,
  select,
  textarea,
  input {
    color: #303030 !important;
    font-weight: normal !important;
  }
  textarea {
    height: 140px;
  }
  .fbffield {
    font-size: 14px;
    padding-left: 15px;
  }
  .ccol-lg-4,
  .ccol-lg-8,
  .ccol-lg-12,
  .ccol-lg-6 {
    @media (min-width: 902px) {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
  .fbfgroup {
    min-height: 70px;
  }
}
.is_client-field {
  .fbfst {
    margin-bottom: 5px;
  }
}
.bgfst-group {
  $radius: 3px;
  .fbfr {
    width: 85px;
    height: 45px;
    float: left;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    border: solid 1.4px #c3c3c3;
    background: #f8f8f8;
    padding: 14px 20px 13px;
    &.fbfr-0 {
      margin-right: 15px;
    }
    &.active {
      background: #ffffff;
      color: #00843d;
    }
  }
}
._fbform .fbfr [type="radio"]:checked + label,
._fbform .fbfr [type="radio"]:not(:checked) + label {
  padding-left: 25px;
  line-height: 15px;
}
._fbform .fbfr [type="radio"]:checked + label::before,
._fbform .fbfr [type="radio"]:not(:checked) + label::before {
  width: 16px;
  height: 16px;
}
._fbform .fbfr [type="radio"]:checked + label::after,
._fbform .fbfr [type="radio"]:not(:checked) + label::after {
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
}
.accept-terms {
  min-height: 60px;
  margin-top: 0;
  margin-bottom: 0;
  label {
    font-size: 14px;
    line-height: 1.21;
    margin: 0;
    padding: 0;
  }
}

._fbform .fbfc input:checked + label::after {
  top: 9px !important;
  left: 10px !important;
}
._fbform .fbfc label::before {
  padding: 12px;
  float: left;
  margin-top: 4px;
}
.terms-text {
  float: left;
  width: calc(100% - 45px);
}
.error {
  font-size: 12px;
  color: red;
}
.form .n-row {
  margin-bottom: 1.8rem;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 990px) {
    margin-bottom: 0;
  }
  .ccol-lg-6 {
    @media (max-width: 990px) {
      margin-bottom: 0;
      min-height: 100px;
    }
  }
  .ccol-lg-12 {
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
  }
}
.form .n-row-submit {
  .recaptcha-box {
    margin-bottom: 0;
  }
  @media (min-width: 901px) {
    height: 80px;
    position: relative;
  }
  @media (max-width: 900px) {
    height: 100%;
  }
  .ccol-lg-8,
  .ccol-lg-4 {
    position: relative;
    @media (max-width: 900px) {
      width: 100%;
      float: left;
      clear: both;
      margin-bottom: 25px;
    }
  }
  .fbfbtn {
    bottom: 3px;
    right: 0;
    position: absolute;
    @media (max-width: 900px) {
      position: relative;
      right: auto;
      bottom: auto;
    }
  }
}
.form .n-row-at {
  @media (min-width: 902px) {
    margin-bottom: 1.2rem;
  }
}
.recaptcha-box {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
._fbform .fbfbtn {
  &.disabled {
    opacity: 0.65;
    box-shadow: none;
    cursor: not-allowed;
  }
}

._fbform .form .n-row {
  .accept-terms label .terms-text,
  .fbfst,
  .lbl-input {
    font-size: 14px;
  }
}
</style>
