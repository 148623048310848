import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/i18n";
import AxiosAPI from "./services/axios";
import interceptors from "@/utils/interceptors";
import store from "./store";
import helperList from "@/utils/helpers";
import VModal from "vue-js-modal";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMask from 'v-mask'
Vue.use(VueMask);

import "./assets/scss/main.scss";

Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
    scrollable: true
  }
});

Vue.config.productionTip = false;

Vue.prototype.$http = AxiosAPI;
Vue.prototype.$store = store;
Vue.prototype.$helperList = helperList;
interceptors();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

let app;
if (!app) {
  app = new Vue({
    el: "#app",
    i18n,
    router,
    created() {},
    render: h => h(App)
  });
}