<template>
  <div>
    <div class="nav-bar-container bg-base-green">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar
          type="dark"
          variant="primary"
          class="d-none d-lg-block bg-base-green"
          id="top-menu"
          role="top-menu"
        >
          <b-navbar-nav class="ml-auto" align="right"> </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>
    <div class="nav-bar-container bg-white">
      <b-container fluid="lg" class="pl-0 pr-0">
        <b-navbar type="light" variant="faded" class="bg-white" id="main-menu">
          <b-navbar-brand :href="headerLink">
            <img
              src="../assets/images/logo.svg"
              class="d-inline-block align-top d-lg-block d-md-none desktop-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
            <img
              src="../assets/images/logo_mobile.png"
              class="d-inline-block align-top d-lg-none mobile-logo"
              alt="FirstBank Logo"
              title="Inicio"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <!--          <b-navbar-nav class="float-right navbar-right ml-auto language-nav">-->
          <!--            <LanguageSwitch />-->
          <!--          </b-navbar-nav>-->
          <b-navbar-nav class="back-menu-box" id="block-linkatras">
            <b-nav-form>
              <b-button href="https://1firstbank.com">
                1firstbank.com
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
      </b-container>
    </div>

    <div class="fbfBanner">
      <div class="pl-0 pr-0 container-lg">
        <div class="navbar" v-if="content">
          <div class="content" v-html="content.body.value">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LanguageSwitch from "./LanguageSwitch/Index";
import * as contentAPI from "@/api/content";
export default {
  name: "SiteHeader",
  // components: { LanguageSwitch },
  data: function() {
    return {
      language: this.$i18n.locale,
      headerLink: "/es",
      content: null,
      default: {
        uuid: "ca72936f-be9d-43b3-b2fa-bb9b38b3ce14",
        nid: 724,
        title: "Header",
        type: "fb_block_section"
      }
    };
  },
  methods: {
    updateLanguage() {
      this.language = this.$i18n.locale;
      this.headerLink = "/es";
      if (this.language === "en") {
        this.headerLink = "/en";
      }
    },
    getHeader() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    }
  },
  watch: {
    $route() {
      this.updateLanguage();
      this.getHeader();
    }
  },
  created() {
    this.getHeader();
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.site-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}
.desktop-logo {
  display: none !important;
  @media (min-width: 902px) {
    display: block !important;
  }
}
.mobile-logo {
  display: none !important;
  @media (max-width: 901px) {
    display: block !important;
  }
}
.fbfBanner h1 {
  text-align: center;
  font-size: 45px;
  line-height: 1.13;
  letter-spacing: -0.84px;
  font-weight: bold;
  width: 100%;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    text-align: left;
  }
}
.nav-link {
  border-right: 1px solid #fff;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Helvetica", Arial, sans-serif !important;
}
</style>
