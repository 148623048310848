<template>
  <div class="fbfContent" :class="languageChange">
    <div class="site__wrapper">
      <div class="site__container">
        <div class="_fbthank">
          <img src="../assets/images/_checkmark.svg" alt="" />
          <div
            class="content-fb"
            v-if="content"
            v-html="content.body.value"
          ></div>
          <div class="btn-wp">
            <router-link to="/" class="fbfbtn">{{ $t("back_to") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "Thanks",
  data() {
    return {
      templateName: "Thanks",
      language: this.$i18n.locale,
      content: null,
      helpers: this.$helperList,
      isReady: false,
      default: {
        uuid: "b143e8d6-cd7c-4e66-ad47-a0e66073800a",
        nid: 725,
        title: "Header",
        type: "fb_block_section"
      }
    };
  },
  created() {
    this.getThanks();
  },
  computed: {
    languageChange() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getThanks() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
        .getContent({
          type: this.default.type,
          uuid: this.default.uuid,
          lang: this.language
        })
        .then(data => {
          content = data.data.data;
          this.content = content;
        })
        .catch(error => {
          console.log("ERROR ON setContent function", error);
          content = null;
        })
        .finally(() => {
          if (content) {
            this.isReady = true;
          }
          // this.content = content;
          // this.isReady = true;
        });
    }
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getThanks();
    }
  },
  mounted () {
    document.body.classList.add('thanks');
  },
};
</script>
<style scoped lang="scss">

.fbfContent {
  min-height: 50vh;
}
.wait-connection {
  min-height: 80vh;
}
p,
a,
h1 {
  text-align: center;
}
.btn-wp {
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: 155px;
  display: block;
}
.fbfbtn {
  float: left;
  color: #0e845c;
  display: block !important;
  text-decoration: underline;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background-image: url("~@/assets/images/icons/left-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    display: block;
    float: left;
    margin-top: 4px;
    margin-right: 2px;
  }
}
</style>
