<template>
  <div>
    <!-- Footer -->
    <b-container role="footer" class="footer-wrapper" fluid="flex">
      <div class="footer footer-bottom container-lg">
        <div class="region region-footer-bottom">
          <section class="footer-icon-block block">
            <div class="field field--name-body field--type-text-with-summary">
              <p>
                <img
                        role="img"
                        alt="fdic"
                        aria-label="fdic"
                        srcset="@/assets/images/logo3.png"
                        src="@/assets/images/logo3.png"
                />
                <img
                        role="img"
                        alt="Logo Member FDIC"
                        aria-label="Logo Member FDIC"
                        srcset="@/assets/images/logo1.png"
                        src="@/assets/images/logo1.png"
                />
                <img
                        role="img"
                        alt="fdic"
                        aria-label="fdic"
                        srcset="@/assets/images/logo2.png"
                        src="@/assets/images/logo2.png"
                />
              </p>
            </div>
          </section>
          <section class="copyright-block block">
            <div class="body body-footer">
              <div class="footer__bar1" v-if="content">
                <p class="copyright" v-if="content.body" v-html="content.body.value">
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import * as contentAPI from "@/api/content";
export default {
  name: "SiteFooter",
  data() {
    return {
      templateName: "Footer",
      language: this.$i18n.locale,
      default: {
        uuid: "ed8fb951-2509-40f1-ae26-e9e6b45e799d",
        nid: 723,
        title: "Footer",
        type: "fb_block_section"
      },
      isReady: false,
      content: null,
      helpers: this.$helperList,
    };
  },
  components: {},
  computed: {},
  methods: {
    getLegal() {
      // const helpers = this.helpers;
      this.isReady = false;
      let content = null;
      contentAPI
              .getContent({
                type: this.default.type,
                uuid: this.default.uuid,
                lang: this.language
              })
              .then(data => {
                content = data.data.data;
                this.content = content;
              })
              .catch(error => {
                console.log("ERROR ON setContent function", error);
                content = null;
              })
              .finally(() => {
                if(content){
                  this.isReady = true;
                }
                // this.content = content;
                // this.isReady = true;
              });
    },
  },
  watch: {
    $route() {
      this.language = this.$i18n.locale;
      this.getLegal();
    }
  },
  created() {
    this.getLegal();
  },
};
</script>
<style scoped lang="scss">
  .footer__bar1{
    background: #303030;
  }
.field--name-body {
  text-align: left;
}
</style>
