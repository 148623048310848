export default {
  cleanPhone(phone) {
    const newPhone = phone
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    // console.log("New phone", newPhone);
    return newPhone;
  },
  getServices: function(lang) {
    if(lang === "en"){
      return this.serviceListEN();
    }

    return this.serviceListES();
  },
  serviceListEN: function() {
    return [
      "Account Solutions",
      "Payments and Collections Solutions",
      "Deposit Solutions",
      "Fraud Prevention Solutions",
      "Merchant Solutions",
      "International Solutions",
      "Other Solutions",
    ]
  },
  serviceListES: function() {
    return [
      "Soluciones de cuentas",
      "Soluciones de Pagos y Cobros",
      "Soluciones de depósito",
      "Soluciones de prevención de fraude",
      "Soluciones para Merchants",
      "Soluciones Internacionales",
      "Otras Soluciones",
    ]
  },
  getBranches: function() {
    return [
      "Aguada",
      "Aguadilla",
      "Arecibo",
      "Bayamón - Drive-In",
      "Bayamón - Forest Hills",
      "Bayamón - Plaza del Parque",
      "Bayamón - Rexville",
      "Bayamón - Santa Rosa Mall",
      "Cabo Rojo",
      "Caguas - Plaza Centro",
      "Canóvanas - The Outlet Mall 66",
      "Cayey",
      "Condado",
      "Condado II",
      "De Diego",
      "Dorado",
      "El Señorial",
      "Fajardo",
      "Galería Los Paseos",
      "Guayama",
      "Guaynabo",
      "Humacao Plaza",
      "Isla Verde II",
      "Las Americas",
      "Las Catalinas I",
      "Las Catalinas Mall II",
      "Levittown",
      "Manatí",
      "Mayagüez - Western Plaza",
      "Mayagüez Mall",
      "Muñoz Rivera",
      "Parque Escorial",
      "Plaza Carolina",
      "Plazoleta del Condado",
      "Ponce - Centro del Sur",
      "Ponce - Coto Laurel",
      "San Francisco",
      "San Patricio",
      "San Sebastian ",
      "Santurce - Ponce de León",
      "Toa Alta - Plaza Aquarium",
      "Trujillo Alto",
      "Vega Baja",
      "Viejo San Juan",
      "Yauco"
    ];
  },
  getTowns() {
    return [
      "Adjuntas",
      "Aguada",
      "Aguadilla",
      "Aguas Buenas",
      "Aibonito",
      "Añasco",
      "Arecibo",
      "Arroyo",
      "Barceloneta",
      "Barranquitas",
      "Bayamón",
      "Cabo Rojo",
      "Caguas",
      "Camuy",
      "Canóvanas",
      "Carolina",
      "Cataño",
      "Cayey",
      "Ceiba",
      "Ciales",
      "Cidra",
      "Coamo",
      "Comerío",
      "Corozal",
      "Culebra",
      "Dorado",
      "Fajardo",
      "Florida",
      "Guánica",
      "Guayama",
      "Guayanilla",
      "Guaynabo",
      "Gurabo",
      "Hatillo",
      "Hormigueros",
      "Humacao",
      "Isabela",
      "Jayuya",
      "Juana Díaz",
      "Juncos",
      "Lajas",
      "Lares",
      "Las Marías",
      "Las Piedras",
      "Loíza",
      "Luquillo",
      "Manatí",
      "Maricao",
      "Maunabo",
      "Mayagüez",
      "Moca",
      "Morovis",
      "Naguabo",
      "Naranjito",
      "Orocovis",
      "Patillas",
      "Peñuelas",
      "Ponce",
      "Quebradillas",
      "Rincón",
      "Río Grande",
      "Sabana Grande",
      "Salinas",
      "San Germán",
      "San Juan",
      "San Lorenzo",
      "San Sebastián",
      "Santa Isabel",
      "Toa Alta",
      "Toa Baja",
      "Trujillo Alto",
      "Utuado",
      "Vega Alta",
      "Vega Baja",
      "Vieques",
      "Villalba",
      "Yabucoa",
      "Yauco"
    ];
  }
};
