import { Trans } from "@/plugins/translation";
import Home from "@/views/Home";
import Thanks from "@/views/Thanks";

export default [
  {
    path: "/:lang",
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "thanks",
        name: "thanksPage",
        component: Thanks
      },
      {
        path: "",
        name: "Home",
        component: Home
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: "*",
    // eslint-disable-next-line no-unused-vars
    redirect(to) {
      return Trans.getUserSupportedLang();
    }
  }
];
