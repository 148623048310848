<template>
  <div id="app">
    <SiteHeader />
<!--    <div class="graphic-sep"></div>-->
    <router-view />
      <SiteFooter />
  </div>
</template>
<script>
import SiteHeader from "@/components/Header";
import SiteFooter from "./components/Footer";
export default {
  components: {
      SiteFooter,
    SiteHeader
  }
};
</script>
<style scoped lang="scss">
    .field--name-body {
        text-align: left;
    }
    .footer-wrapper .footer-bottom {
        padding-left: 2rem;
        padding-right: 2rem;
    }
</style>