import { post } from "axios";

async function postWebForm(body, locale) {
  const restEndpoint = `/webform_rest/submit?_format=json`;
  const API_URL = `${process.env.VUE_APP_HDRUPAL_BASE_URL}${locale}${restEndpoint}`
  const config = {};
  const request = await post(API_URL, body, config);

  return request;
}

export { postWebForm };
